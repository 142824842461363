import { NgModule, LOCALE_ID, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserIdleModule } from 'angular-user-idle';
import { environment as env } from '../environments/environment';
import { AuthInterceptorService } from './shared/services/interceptor';
import { UserActiveDirective } from './shared/directives/user-active.directive';
import { OrderByPipe } from './shared/pipes/orderBy.pipe';
import { RutFormatPipe } from './shared/pipes/rutFormat.pipe';
import { PhoneDirective } from './shared/directives/phone.directive';
import { SumTdPipe } from './shared/pipes/sumTd.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AfterPrintDirective } from './shared/directives/after-print.directive';
import { OnlyTextDirective } from './shared/directives/only-text.directive';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SafePipe } from './shared/pipes/safe.pipe';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import {MatDialogModule} from "@angular/material/dialog";
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store'
import { userInfoReducer } from './state/reducers/userInfo.reducer';
import { selectedOrganizationReducer } from './state/reducers/selectedOrganizationReducers';
import { SharedComponentsModule } from 'src/app/components/shared-components.module'
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import { LoginComponent } from './login/login.component';

registerLocaleData(es);

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_hNRZ94apM',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: '194dm6rna4j7omncn4ohpod18q',
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'https://web-dev-usuarios-gestionatupyme.auth.us-east-1.amazoncognito.com',
          scopes: [
            // 'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: ['http://localhost:4200/callback'],
          redirectSignOut: ['http://localhost:4200/logout'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  }
});

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    AppComponent,
    UserActiveDirective,
    OrderByPipe,
    RutFormatPipe,
    SumTdPipe,
    PhoneDirective,
    AfterPrintDirective,
    OnlyTextDirective,
    SafePipe,
    LandingPageComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    ModalModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    UserIdleModule.forRoot(
    {idle: env.inactivity.initInactivity,  //tiempo que esperamos al usuario quieto
    timeout: env.inactivity.timeoutInactivity, //tiempo que esperamos para tomar accion
    ping: env.inactivity.respawnInactivity}), //no se esta usando
    ToastrModule.forRoot(),
    MatProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedComponentsModule,
    StoreModule.forRoot({userInfo: userInfoReducer, selectedOrganization: selectedOrganizationReducer}, {}),
    AmplifyAuthenticatorModule
  ],
  providers: [
    // provideToastr(),
    { provide: LOCALE_ID, useValue: 'es-CL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    // { 
    //   provide: HIGHCHARTS_MODULES, 
    //   useFactory: () => [HighchartsMore, HighchartsSolidGauge] 
    // }, 
    // {
    //   // provide: AsincronoService
    // }
  ],
  // entryComponents: [
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
